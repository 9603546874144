import { Controller } from "@hotwired/stimulus"
import $ from "jquery";

export default class extends Controller {
  static values = { type: String };

  connect () {
    this.bind_dropdown();
    // console.debug('URL: ', this.url);
  }

  initialize() {
    this.dropdown = null;
  }

  bind_dropdown() {
    this.dropdown = $(this.element).dropdown({
      throttle: 1000,
      apiSettings: {
        cache: false,
        throttle: 700,
        url: this.url
      },
      showOnFocus: true,
      forceSelection: false,
      match: 'text',
      minCharacters: 3,
      saveRemoteData: false,
      placeholder: this.placeholder,
      clearable: this.clearable
    })
  }

  get url() {
    let url = '/search/clients?q={query}';
    if (this.hasTypeValue) {
      url += '&type=' + this.typeValue;
    }
    return url;
  }

  get placeholder() {
    return this.element.classList.contains('use-placeholder') ? 'auto' : false
  }

  get clearable() {
    return this.element.classList.contains('clearable')
  }

  disconnect() {
  }
};
