import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { wrapper: String }
  static targets = ["entry"]

  add(event) {
    event.preventDefault()

    fetch("/billing/invoices/new_item", {
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    })
      .then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
  }

  remove(event) {
    event.preventDefault()
    const wrapper = event.target.closest("[data-nested-form-target='entry']")

    if (wrapper.dataset.newRecord === "true") {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = "1"
      wrapper.style.display = "none"
    }
  }
}
