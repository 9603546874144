// app/javascript/controllers/invoice_form_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["items"]

  connect() {
    console.log("InvoiceFormController#connect")
    this.calculateTotals()
    this.setupEventListeners()
  }

  setupEventListeners() {
    this.itemsTarget.addEventListener("input", (event) => {
      // if (event.target.matches("[data-invoice-form-target='itemTotal'] input")) {
        const item = event.target.closest("[data-nested-form-target='entry']")
        this.calculateItemTotal(item)
      // }
    })
  }

  calculateItemTotal(itemElement) {
    const price = parseFloat(itemElement.querySelector("[name$='[unit_price]']").value) || 0
    const quantity = parseInt(itemElement.querySelector("[name$='[quantity]']").value) || 0
    const totalElement = itemElement.querySelector("[data-invoice-form-target='itemTotal']")

    totalElement.textContent = this.formatCurrency(price * quantity)
  }

  calculateTotals() {
    this.itemsTarget.querySelectorAll("[data-nested-form-target='entry']").forEach(item => {
      this.calculateItemTotal(item)
    })
  }

  formatCurrency(amount) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount)
  }
}
